import { Consignee, Consignor } from '../../TransitOperationEditor/form/schemas/tradersSchema'
import { AdditionalInformation } from '../../TransitOperationEditor/form/schemas/commonConsignmentSchemas'

export function hasText(str: string | null | undefined) {
  return str !== undefined && str !== null && str.trim().length > 0
}

export function excludeDeleted(item: Partial<{ deleted?: boolean }>) {
  return !item.deleted
}

export function isConsignee(trader: Consignor | Consignee): trader is Consignee {
  return trader.target === 'CONSIGNEE'
}
export function isConsignor(trader: Consignor | Consignee): trader is Consignor {
  return trader.target === 'CONSIGNOR'
}

function nonNullTrader(trader: Consignee | Consignor) {
  if (isConsignor(trader)) {
    if (!hasText(trader.contactPerson?.name) || !hasText(trader.contactPerson?.email) || !hasText(trader.contactPerson?.telephone)) {
      return false
    }
  }
  if (hasText(trader.identificationNumber)) {
    return true
  }

  if (hasText(trader.name)) return true

  return trader.address
    && (
      hasText(trader.address.city)
      || hasText(trader?.address.country)
      || hasText(trader?.address.postcode)
      || hasText(trader?.address.streetAndNumber))
}

export function nonNullConsignee(consignee: Consignee) {
  return consignee.target === 'CONSIGNEE' && nonNullTrader(consignee)
}
export function nonNullConsignor(consignor: Consignor) {
  return consignor.target === 'CONSIGNOR' && nonNullTrader(consignor)
}

export function someAdditionalInformationItemHasExsCode(additionalInformations: AdditionalInformation[]) {
  return additionalInformations.some((item) => item.code === '30600')
}

export function tradersEqual(previous: Consignor | Consignee | null, current: Consignor | Consignee | null) {
  if (previous === null && current === null) return true

  if (current === null) return true
  if (previous === null) return true
  if (previous.target !== current.target) {
    throw Error('Traders not comparable')
  }

  if (previous.name !== current.name) return false
  if (previous.identificationNumber !== current.identificationNumber) return false

  if (previous.address?.city !== current.address?.city) return false
  if (previous.address?.country !== current.address?.country) return false
  if (previous.address?.postcode !== current.address?.postcode) return false
  if (previous.address?.streetAndNumber !== current.address?.streetAndNumber) return false

  if (isConsignor(previous) && isConsignor(current)) {
    if (previous.contactPerson?.name !== current.contactPerson?.name) return false
    if (previous.contactPerson?.email !== current.contactPerson?.email) return false
    if (previous.contactPerson?.telephone !== current.contactPerson?.telephone) return false
  }

  return true
}

export function parseNumber(num: number | null | undefined) {
  if (num === null) return null
  if (num === undefined) return undefined

  return Number.isNaN(num) ? null : Number(num)
}
export function parseStringToNumber(num: string | null | undefined) {
  if (num === null) return null
  if (num === undefined) return undefined

  return parseNumber(Number(num))
}

export function adjustToHumanReadableField(field: string) {
  if (!(field.includes('[') && field.includes(']'))) {
    return field
  }

  const matches = field.matchAll(/\[.]/g)
  let isDone: undefined | boolean = false
  let fieldPathWithArray = `${field}`

  while (!isDone) {
    const nextItem: IteratorResult<RegExpMatchArray, RegExpMatchArray> = matches.next()
    isDone = nextItem.done
    if (isDone) {
      break
    }

    const arrayIndexAccessor = nextItem.value['0']
    const propertyAccessorWithoutBrackets = arrayIndexAccessor?.substring(1, arrayIndexAccessor.length - 1) ?? '0'
    const oneBasedAccessor = Number(propertyAccessorWithoutBrackets) + 1

    let precedingField = ''
    const matchIndex = nextItem.value.index ?? 0
    if (matchIndex > 0) {
      precedingField = fieldPathWithArray.substring(0, matchIndex - 1)
    }

    const remainingField = fieldPathWithArray.substring(matchIndex + arrayIndexAccessor.length)
    fieldPathWithArray = `${precedingField}[${oneBasedAccessor}]${remainingField}`
  }
  return fieldPathWithArray
}

export function replaceListSquareBracketsWithDotDelimiter(path: string) {
  return path.replace('[', '.')
    .replace('].', '.')
}
