import React, { useContext, useEffect, useState } from 'react'
import { useExpanded, usePagination, useTable } from 'react-table'
import { useSticky } from 'react-table-sticky'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import TableHeader from '../../../Transits/Table/TableHeader'
import getTableHeaders, { getTransitOperationAuditStatusOptions } from './TableConfig'
import { TableFilter } from '../../../../types/PageResponse'
import LoadingBackdrop from '../../../../components/LoadingBackdrop'
import { TablePagination } from '../../../Transits/Table/TablePagination'
import { NotificationContext } from '../../../../context/NotificationContext'
import TableBody from './TableBody'
import TransitOperationAuditDecisionModal from '../../../DeclarationAudit/components/TransitOperationAuditDecisionModal'
import useTransitOperationAuditPage from '../../TransitOperationEditor/hooks/useTransitOperationAuditPage/api'
import useTransitOperationAudit from '../../TransitOperationEditor/hooks/useTransitOperationAudit'
import TransitSidebar from '../../common/components/TransitSidebar'
import { TransitOperationAuditPageResponse, TransitOperationAuditPageResponseStatusEnum } from '../../common/models'

export default function AuditTable() {
  const { contextHeaderNotificationMessage } = useContext(NotificationContext)
  const [statusFilter, setStatusFilter] = useState<string>('')
  const [orderBy, setOrderBy] = useState<string>('DESC')
  const [filters, setFilters] = useState<Array<TableFilter>>([])
  const { t, i18n } = useTranslation()
  const toggleHeaderOrder = () => getTableHeaders(t)

  const columns = React.useMemo(() => toggleHeaderOrder(), [isMobile, i18n.language])
  const [data, setData] = useState<Array<any>>([])
  const [totalPages, setTotalPages] = useState(0)
  const [loading, setLoading] = useState(false)
  const [isDecisionLoading, setIsDecisionLoading] = useState(false)
  const [isDecisionModalVisible, setIsDecisionModalVisible] = useState(false)
  const [reason, setReason] = useState('')
  const [auditStatus, setAuditStatus] = useState('')
  const [isTransitSidebarOpen, setIsTransitSidebarOpen] = useState(false)
  const [transitOperationId, setTransitOperationId] = useState<number | null>(null)
  const [originalRow, setOriginalRow] = useState<TransitOperationAuditPageResponse | null>(null)

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
      },
      manualPagination: true,
      pageCount: totalPages,
    },
    useExpanded,
    useSticky,
    usePagination,
  )
  const { updateTransitOperationAudit } = useTransitOperationAudit()

  const {
    getAllTransitOperationAudits,
  } = useTransitOperationAuditPage({
    filters,
    status: statusFilter,
    pageIndex,
    pageSize,
  })

  useEffect(() => {
    if (getAllTransitOperationAudits.data?.data !== undefined
      && getAllTransitOperationAudits.isFetched
      && !getAllTransitOperationAudits.isLoading) {
      setTotalPages(getAllTransitOperationAudits.data?.data?.totalPages)
      setData(getAllTransitOperationAudits.data.data.content)
      if (!getAllTransitOperationAudits.isLoading) setLoading(false)
    }
  }, [getAllTransitOperationAudits])

  const toggleDecisionModalVisibility = () => {
    setIsDecisionModalVisible(!isDecisionModalVisible)
  }

  const decide = async () => {
    if (auditStatus === 'DECLINED' || auditStatus === 'PROHIBITED_DESCRIPTION') {
      if (reason.length === 0) {
        toast.error(t('audit.inputError'))
        setIsDecisionLoading(false)

        return
      }
    }
    if (originalRow === null) {
      return
    }

    const auditId = originalRow.id
    if (auditId === null || auditId === undefined) {
      return
    }

    setOriginalRow({
      ...originalRow,
      reason,
      status: auditStatus as TransitOperationAuditPageResponseStatusEnum,
    })
    await updateTransitOperationAudit(auditId, { status: auditStatus, reason })

    setIsDecisionModalVisible(!isDecisionModalVisible)
    if (getAllTransitOperationAudits.data?.data !== undefined
      && getAllTransitOperationAudits.isFetched
      && !getAllTransitOperationAudits.isLoading) {
      setTotalPages(getAllTransitOperationAudits.data?.data?.totalPages)
      setData(getAllTransitOperationAudits.data.data.content)
      if (!getAllTransitOperationAudits.isLoading) {
        setLoading(false)
        setIsDecisionLoading(false)
        setIsDecisionModalVisible(false)
        setIsTransitSidebarOpen(false)
        setReason('')
      }
    }
  }

  const changeAside = (input: boolean, transitOperation?: number, tableRow?: TransitOperationAuditPageResponse) => {
    setTransitOperationId(transitOperation || null)
    setReason('')
    if (tableRow) {
      setOriginalRow(tableRow)
    }
    setIsTransitSidebarOpen(!isTransitSidebarOpen)
  }

  return (
    <>
      <div
        className={`table__container table-responsive declaration-table__container ${isMobile ? 'not-in-mobile' : ''} ${contextHeaderNotificationMessage !== '' ? 'notification--open' : ''}`}
      >
        <table {...getTableProps()} className="table sticky table-hover fixed-columns mb-0">
          <TableHeader
            /* @ts-ignore */
            headerGroups={headerGroups}
            selectFilterOptions={getTransitOperationAuditStatusOptions()}
            selectFilterState={{
              selectFilter: statusFilter,
              setSelectFilter: setStatusFilter,
            }}
            filterState={{
              filters,
              setFilters,
            }}
            orderState={{
              orderBy,
              setOrderBy,
            }}
          />
          {!loading && (
            <TableBody
              asideAction={changeAside}
              prepareRow={prepareRow}
              getTableBodyProps={getTableProps}
              openDecisionModal={(auditRow: TransitOperationAuditPageResponse) => {
                setOriginalRow(auditRow)
                setIsDecisionModalVisible(!isDecisionModalVisible)
              }}
              page={page}
            />
          )}
          <LoadingBackdrop loading={loading} />
        </table>
      </div>
      <TablePagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
      {
        isDecisionModalVisible && (
          <TransitOperationAuditDecisionModal
            title={t('buttons.decideAuditHeader')}
            isVisible={isDecisionModalVisible}
            toggleVisibility={toggleDecisionModalVisibility}
            isLoading={isDecisionLoading}
            onChange={setReason}
            onSelect={setAuditStatus}
            onConfirmation={() => {
              setIsDecisionLoading(true)
              decide()
            }}
            onRejection={() => {
              setIsDecisionModalVisible(!isDecisionModalVisible)
            }}
            originalRow={originalRow}
          />
        )
      }
      {
        transitOperationId !== null && (
          <TransitSidebar
            isVisible={isTransitSidebarOpen}
            toggleVisibility={() => setIsTransitSidebarOpen(!isTransitSidebarOpen)}
            transitOperationId={transitOperationId}
            originalRow={originalRow}
            openAuditModal={() => {
              setIsDecisionModalVisible(!isDecisionModalVisible)
            }}
          />
        )
      }
    </>
  )
}
