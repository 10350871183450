import { t } from 'i18next'
import { BadRequestPayload, EntityNotFoundError } from '../types/Errors'

export function mapReasonToTranslationString({ reason }: BadRequestPayload) {
  if (reason === null || reason === undefined) {
    return null
  }

  let translatedString
  switch (reason) {
    case 'NotBlank':
    case 'NotEmpty':
      translatedString = t('errors.notBlank')
      break
    case 'NotNull':
      translatedString = t('errors.notNull')
      break
    case 'Pattern':
      translatedString = t('errors.pattern')
      break
    case 'GuaranteeFormula':
      translatedString = t('errors.guaranteeFormula')
      break
    case 'Uppercase':
    case 'ClassifierExists':
    case 'InvalidValue':
      translatedString = t('errors.invalidClassifier')
      break
    case 'CommodityCode':
      translatedString = t('errors.codelist')
      break
    case 'ValidInputText':
      translatedString = t('errors.invalidInputText')
      break
    default:
      translatedString = ''
  }

  return translatedString
}

export function mapEntityToTranslatedString({ entity }: EntityNotFoundError) {
  if (entity === null || entity === undefined) {
    return null
  }
  const translatedString = ''

  if (entity === 'Declaration') {
    return t('entity.declaration')
  }
  if (entity === 'Customer') {
    return t('entity.customer')
  }
  if (entity === 'Restriction') {
    return t('entity.restriction')
  }

  return translatedString
}
