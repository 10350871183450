import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NestedFieldScope, ObjectScope, ScopeProps } from 'types/DeclarationP5'
import { useFormContext } from 'react-hook-form'
import { Dropdown, DropdownButton } from 'react-bootstrap'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import FormField from '../../components/FormField'
import FormSelect from '../../components/FormSelect'
import Input from '../../components/Input'
import useCodelist from '../../../common/hooks/useCodelist'
import CommodityCodeSearch from '../../components/CommodityCodeSearch'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import useCurrencyExchangeRate from '../../../common/hooks/useCurrencyExchangeRate'
import { AmountUnit } from '../../form/schemas/consignmentItemSchema'

const expectedScope = /houseConsignment\.\d+\.consignmentItem\.\d+/g
type ConsignmentItemScope = NestedFieldScope<`houseConsignment.${number}.consignmentItem.${number}`>
const isConsignmentItemScope = (scope: ObjectScope): scope is `houseConsignment.${number}.consignmentItem.${number}` => (
  Array.from(scope.matchAll(expectedScope))?.length ?? 0) > 0

function GoodsData({ scope }: ScopeProps<ObjectScope>) {
  if (!isConsignmentItemScope(scope)) throw Error('Unable to narrow, invalid scope')
  const { t } = useTranslation()
  const [, countriesOptions] = useCodelist('COUNTRIES')
  const {
    register, getValues, setValue,
  } = useFormContext<DeclarationForm>()
  const office = getValues('departureCustomsOffice')
  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])
  const [inputCurrencyCountry, setInputCurrencyCountry] = useState<string | null>(getValues(`${scope}.initialPriceCurrency`))
  const { exchangeRate, exchangeRateLoading } = useCurrencyExchangeRate(inputCurrencyCountry)

  useEffect(() => {
    let price = getValues(`${scope}.initialPrice`)
    if (price !== null && exchangeRate !== null && exchangeRate > 0) {
      price = Number.parseFloat((price * (1 / exchangeRate)).toFixed(3))
    }
    setValue(`${scope}.price`, price)
  }, [getValues(`${scope}.initialPrice`), exchangeRateLoading, exchangeRate])

  useEffect(() => {
    switch (getValues(`${scope}.initialPriceCurrency`)) {
      case 'EUR':
        setInputCurrencyCountry(null)
        break
      case 'USD':
        setInputCurrencyCountry('US')
        break
      case 'GBP':
        setInputCurrencyCountry('GB')
        break
      case 'RON':
        setInputCurrencyCountry('RO')
        break
      case 'BGN':
        setInputCurrencyCountry('BG')
        break
      default:
        setInputCurrencyCountry(null)
    }
  }, [getValues(`${scope}.initialPriceCurrency`)])

  return (
    <section className="py-3">
      <CollapsibleColumn
        scope={scope}
        columnId="declaration-goods-data"
        headingLabel={t('declaration.p5.goodsData')}
      >
        <FormField labelKey="declaration.p5.referenceNumberUCR">
          <Input
            <ConsignmentItemScope>
            type="text"
            field={`${scope}.referenceNumberUCR`}
            labelKey="declaration.p5.referenceNumberUCR"
            placeholder={t('declaration.p5.referenceNumberUCR')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.commodityDescriptionOfGoods">
          <Input
            <ConsignmentItemScope>
            maxLength={250}
            type="textarea"
            field={`${scope}.commodityDescriptionOfGoods`}
            labelKey="declaration.p5.commodityDescriptionOfGoods"
            placeholder={t('declaration.p5.commodityDescriptionOfGoods')}
          />
        </FormField>
        <FormField
          scope={`${scope}.commodityHarmonizedSystemSubHeadingCode`}
          labelKey="declaration.p5.commodityCode"
        >
          <CommodityCodeSearch
            scope={scope}
            country={country}
          />
        </FormField>
        <FormField labelKey="declaration.p5.goodsMeasureGrossMass">
          <Input
            <ConsignmentItemScope>
            field={`${scope}.goodsMeasureGrossMass`}
            labelKey="declaration.p5.goodsMeasureGrossMass"
            type="number"
            inputMode="decimal"
            min={0}
            step={0.001}
            placeholder={t('declaration.p5.goodsMeasureGrossMass')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.goodsMeasureNetMass">
          <Input
            <ConsignmentItemScope>
            field={`${scope}.goodsMeasureNetMass`}
            labelKey="declaration.p5.goodsMeasureNetMass"
            type="number"
            inputMode="decimal"
            min={0}
            step={0.001}
            placeholder={t('declaration.p5.goodsMeasureNetMass')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.countryOfDestination">
          <FormSelect
            <ConsignmentItemScope>
            field={`${scope}.countryOfDestination`}
            labelKey="declaration.p5.countryOfDestination"
            options={countriesOptions}
            type="sync"
          />
        </FormField>
        <FormField labelKey="declaration.p5.goodsItemPrice">
          <div className="input-group">
            <Input
              <ConsignmentItemScope>
              field={`${scope}.initialPrice`}
              labelKey="declaration.p5.goodsItemPrice"
              type="number"
              inputMode="decimal"
              min={0}
              step={0.001}
              placeholder={t('declaration.p5.goodsItemPrice')}
            />
            <div className="input-group-append">
              <DropdownButton
                title={`${getValues(`${scope}.initialPriceCurrency`)}`}
                onSelect={(eventKey) => {
                  if (eventKey) {
                    setValue(`${scope}.initialPriceCurrency`, eventKey)
                  }
                }}
                defaultValue="EUR"
              >
                <Dropdown.Item className="border border-bottom-0" eventKey="EUR">EUR</Dropdown.Item>
                <Dropdown.Item className="border border-bottom-0" eventKey="USD">USD</Dropdown.Item>
                <Dropdown.Item className="border border-bottom-0" eventKey="GBP">GBP</Dropdown.Item>
                <Dropdown.Item className="border border-bottom-0" eventKey="RON">RON</Dropdown.Item>
                <Dropdown.Item className="border" eventKey="BGN">BGN</Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="input-group pt-1">
            {
              getValues(`${scope}.initialPriceCurrency`) !== 'EUR' && (
                <div className="input-group">
                  <Input
                    <ConsignmentItemScope>
                    field={`${scope}.price`}
                    labelKey="declaration.p5.goodsItemPrice"
                    type="number"
                    inputMode="decimal"
                    min={0}
                    step={0.001}
                    placeholder={t('declaration.p5.goodsItemPrice')}
                    disabled
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">
                      {t('declaration.p5.goodsItemPrice', { context: 'unit' })}
                    </span>
                  </div>
                </div>
              )
            }
          </div>
        </FormField>
        <FormField labelKey="declaration.p5.goodsItemDutyRate">
          <div className="input-group">
            <div className="input-group-prepend">
              <label className="input-group-text px-2" htmlFor={`${scope}.dutyRateUnit_PERCENTAGE`}>
                <div className="form-check-inline m-0 p-0">
                  <input
                    className="form-check-input mx-1"
                    id={`${scope}.dutyRateUnit_PERCENTAGE`}
                    type="radio"
                    value="PERCENTAGE"
                    {...register(`${scope}.dutyRateUnit` as const)}
                  />
                </div>
              </label>
            </div>
            <Input
              field={`${scope}.dutyRate`}
              labelKey="declaration.p5.goodsItemDutyRate"
              type="number"
              inputMode="decimal"
              min={0}
              step={0.001}
              placeholder={t('declaration.p5.goodsItemDutyRate')}
              disabled={getValues(`${scope}.dutyRateUnit`) === AmountUnit.AMOUNT}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {t('declaration.p5.goodsItemDutyRate', { context: 'unit' })}
              </span>
            </div>
          </div>
          <div className="input-group pt-1">
            <div className="input-group-prepend">
              <label className="input-group-text px-2" htmlFor={`${scope}.dutyRateUnit_AMOUNT`}>
                <div className="form-check-inline m-0 p-0">
                  <input
                    id={`${scope}.dutyRateUnit_AMOUNT`}
                    type="radio"
                    className="form-check-input mx-1"
                    value="AMOUNT"
                    {...register(`${scope}.dutyRateUnit` as const)}
                  />
                </div>
              </label>
            </div>
            <Input
              <ConsignmentItemScope>
              field={`${scope}.dutyAmount`}
              labelKey="declaration.p5.goodsItemVatRate"
              type="number"
              inputMode="decimal"
              min={0}
              step={0.001}
              placeholder={t('declaration.p5.goodsItemDutyRate')}
              disabled={getValues(`${scope}.dutyRateUnit`) === AmountUnit.PERCENTAGE}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {t('declaration.p5.goodsItemPrice', { context: 'unit' })}
              </span>
            </div>
          </div>
        </FormField>

        <FormField labelKey="declaration.p5.goodsItemVatRate">
          <div className="input-group">
            <Input
              <ConsignmentItemScope>
              field={`${scope}.vatRate`}
              labelKey="declaration.p5.goodsItemVatRate"
              type="number"
              inputMode="decimal"
              min={0}
              step={0.001}
              placeholder={t('declaration.p5.goodsItemVatRate')}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {t('declaration.p5.goodsItemVatRate', { context: 'unit' })}
              </span>
            </div>
          </div>
        </FormField>
        <FormField labelKey="declaration.p5.goodsItemExcise">
          <div className="input-group">
            <Input
              <ConsignmentItemScope>
              field={`${scope}.excise`}
              labelKey="declaration.p5.goodsItemExcise"
              type="number"
              inputMode="decimal"
              min={0}
              step={0.001}
              placeholder={t('declaration.p5.goodsItemExcise')}
            />
            <div className="input-group-append">
              <span className="input-group-text">
                {t('declaration.p5.goodsItemExcise', { context: 'unit' })}
              </span>
            </div>
          </div>
        </FormField>
      </CollapsibleColumn>
    </section>
  )
}

export default GoodsData
