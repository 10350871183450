import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { getSelectCountryFilter } from 'components/react-select/CountryFunctions'
import { useFormContext } from 'react-hook-form'
import CollapsibleColumn from '../../components/CollapsibleColumn'
import useCodelist from '../../../common/hooks/useCodelist'
import Input from '../../components/Input'
import FormSelect from '../../components/FormSelect'
import FormField from '../../components/FormField'
import { DeclarationForm, SecurityEnum } from '../../form/schemas/declarationFormSchema'
import R0994Validator from '../../form/validators/R0994'
import { CreateOrUpdateTransitOperationRequestDeclarationTypeEnum } from '../../../common/models'
import DECLARATION_TYPE_OPTIONS from '../../../common/config'

type DeclarationInput = keyof DeclarationForm
const defaultSecurityOptions = [{
  value: SecurityEnum.Enum['0'],

  label: '0 - Not used for safety and security purposes',
},
{
  value: SecurityEnum.Enum['1'],
  label: '1 - ENS',
},
{
  value: SecurityEnum.Enum['2'],
  label: '2 - EXS',
},
{
  value: SecurityEnum.Enum['3'],
  label: '3 - ENS & EXS',
},
]

function GeneralSection() {
  const { t } = useTranslation()
  const [securityOptions, setSecurityOptions] = useState([...defaultSecurityOptions])
  const [, countriesOptions] = useCodelist('COUNTRIES')
  const [, departureCustomsOfficesOptions] = useCodelist('DEPARTURE_CUSTOMS_OFFICES')
  const [, destinationCustomsOfficesOptions] = useCodelist('DESTINATION_CUSTOMS_OFFICES')
  const { getValues, setValue } = useFormContext<DeclarationForm>()
  const houseConsignments = useMemo(() => getValues('houseConsignment'), [getValues('houseConsignment')])

  const minimumConsignmentGrossMass = R0994Validator.getMinimumConsignmentGrossMass(houseConsignments)

  const office = getValues('departureCustomsOffice')
  const country = useMemo(() => office.slice('DEPARTURE_OFFICE_'.length, 'DEPARTURE_OFFICE_'.length + 2), [office])

  useEffect(() => {
    if (country === 'LT') {
      setSecurityOptions(securityOptions.filter((option) => option.value === '0' || option.value === '2'))
      const currentSecurity = getValues('security')
      if (currentSecurity === '1' || currentSecurity === '3') {
        setValue('security', SecurityEnum.Enum['0'])
      }
    } else {
      setSecurityOptions([...defaultSecurityOptions])
    }

    if (country === 'BG' || country === 'GB' || country !== 'RS') {
      return
    }

    if (getValues('declarationType') !== CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T1) {
      setValue('declarationType', CreateOrUpdateTransitOperationRequestDeclarationTypeEnum.T1)
    }
  }, [country])

  return (
    <section className="py-3">
      <CollapsibleColumn
        columnId="declaration-general"
        headingLabel={t('declaration.navbar.general')}
      >

        <FormField labelKey="declaration.p5.mrn">
          <Input<DeclarationInput> field="mrn" labelKey="declaration.p5.mrn" type="text" placeholder={t('declaration.p5.mrn')} disabled />
        </FormField>

        <FormField labelKey="declaration.p5.lrn">
          <Input<DeclarationInput> field="lrn" labelKey="declaration.p5.lrn" type="text" placeholder={t('declaration.p5.lrn')} disabled />
        </FormField>

        <FormField labelKey="declaration.p5.declarationType">
          <FormSelect
            <DeclarationInput>
            field="declarationType"
            labelKey="declaration.p5.declarationType"
            type="sync"
            isDisabled={country !== 'BG' && country !== 'GB' && country !== 'RS' && country !== 'SE'}
            options={DECLARATION_TYPE_OPTIONS}
          />
        </FormField>

        <FormField labelKey="declaration.p5.security">
          <FormSelect
            <DeclarationInput>
            field="security"
            labelKey="declaration.p5.security"
            options={securityOptions}
            type="sync"
          />
        </FormField>

        <FormField labelKey="declaration.p5.departureCustomsOffice">
          <FormSelect
            <DeclarationInput>
            field="departureCustomsOffice"
            labelKey="declaration.p5.departureCustomsOffice"
            options={departureCustomsOfficesOptions}
            type="sync"
          />
        </FormField>

        <FormField labelKey="declaration.p5.destinationCountry">
          <FormSelect
            <DeclarationInput>
            field="countryOfDestination"
            labelKey="declaration.p5.destinationCountry"
            options={countriesOptions}
            customFilterOption={getSelectCountryFilter}
            type="sync"
          />
        </FormField>

        <FormField labelKey="declaration.p5.destinationCustomsOffice">
          <FormSelect
            <DeclarationInput>
            field="destinationCustomsOffice"
            labelKey="declaration.p5.destinationCustomsOffice"
            options={destinationCustomsOfficesOptions}
            type="sync"
          />
        </FormField>

        <FormField labelKey="declaration.p5.grossWeight">
          <Input<DeclarationInput>
            field="grossMass"
            labelKey="declaration.p5.grossWeight"
            type="number"
            min={minimumConsignmentGrossMass}
            inputMode="decimal"
            step={0.001}
            placeholder={t('declaration.p5.grossWeight')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.referenceNumberUCR">
          <Input<DeclarationInput>
            field="referenceNumberUCR"
            type="text"
            labelKey="declaration.p5.referenceNumberUCR"
            placeholder={t('declaration.p5.referenceNumberUCR')}
          />
        </FormField>
        <FormField labelKey="declaration.p5.limitDate">
          <Input<DeclarationInput>
            field="limitDate"
            type="date"
            labelKey="declaration.p5.limitDate"
            placeholder={t('declaration.p5.limitDate')}
          />
        </FormField>
      </CollapsibleColumn>
    </section>
  )
}

export default GeneralSection
