import { useEffect, useState } from 'react'
import { AccessLevel, DeclarationCountry } from '../../context/DeclarationContext'

interface DeclarationMetaData {
  country: DeclarationCountry | null
  departureCustomsOffice: string
  accessLevel: AccessLevel
}

export const CUSTOMS_OFFICE_PREFIX = 'CUSTOMS_OFFICE_'

export function extractDeclarationCountryFromCustomsOffice(departureCustomsOffice: string | null): DeclarationCountry | null {
  if (departureCustomsOffice === null) {
    return null
  }

  const countryCode = departureCustomsOffice.substr(CUSTOMS_OFFICE_PREFIX.length, 2)
  switch (countryCode) {
    case 'LV':
      return DeclarationCountry.LATVIA
    case 'LT':
      return DeclarationCountry.LITHUANIA
    case 'PL':
      return DeclarationCountry.POLAND
    case 'FI':
      return DeclarationCountry.FINLAND
      //  case 'EE':
      // return DeclarationCountry.ESTONIA
    case 'GB':
      return DeclarationCountry.GREAT_BRITAIN
    case 'XI':
      return DeclarationCountry.NORTHERN_IRELAND
    // case 'BG':
    //   return DeclarationCountry.BULGARIA;
    default: {
      return DeclarationCountry.LATVIA
    }
  }
}
function useDeclarationMetaData() {
  const initialState: DeclarationMetaData = {
    country: null,
    departureCustomsOffice: '',
    accessLevel: AccessLevel.VIEW,
  }
  const [{ country, departureCustomsOffice, accessLevel }, setMetaData] = useState<DeclarationMetaData>(initialState)

  const setDepartureCustomsOffice = (customsOffice: string) => {
    setMetaData((prevState) => ({ ...prevState, departureCustomsOffice: customsOffice }))
  }

  const setAccessLevel = (newAccessLevel: AccessLevel) => {
    setMetaData((prevState) => ({ ...prevState, accessLevel: newAccessLevel }))
  }

  const mockDraftCountry = () => {
    setMetaData({
      country: null,
      departureCustomsOffice,
      accessLevel,
    })
  }

  useEffect(() => {
    const newCountry: DeclarationCountry | null = extractDeclarationCountryFromCustomsOffice(departureCustomsOffice)

    setMetaData({
      country: newCountry,
      departureCustomsOffice,
      accessLevel,
    })
  }, [departureCustomsOffice])

  return {
    country,
    setDepartureCustomsOffice,
    accessLevel,
    setAccessLevel,
    mockDraftCountry,
  }
}

export default useDeclarationMetaData
