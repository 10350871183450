import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import DeclarationApi from '../apiConfig'
import { TransitOperationLogResponse } from '../../../common/models'

export default function useTransitOperationLog(transitOperationId: number | undefined) {
  const {
    baseUrl,
    queryKeys: {
      getTransitOperationLog,
    },
  } = DeclarationApi.paths.transitOperationLog

  const fetchTransitOperationLogs = useQuery({
    enabled: !!transitOperationId,
    queryFn: async ({ signal }) => (await axios
      .get<TransitOperationLogResponse[]>(`${baseUrl}/transit-operation/${transitOperationId}`, { signal })).data,
    queryKey: getTransitOperationLog(transitOperationId!),
    staleTime: Infinity, // never stale
  })

  const fetchSubmitMessage = useMutation({
    mutationFn: async (request: {
      transitOperationLogId: number
    }) => (await axios
      .get<Blob>(`${baseUrl}/${request.transitOperationLogId}/transit-operation/${transitOperationId}`, {
      responseType: 'blob',
    })),
  })

  return {
    fetchTransitOperationLogs,
    fetchSubmitMessage,
  }
}
