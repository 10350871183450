import { useContext, useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { mapReasonToTranslationString } from 'helpers/errors.helper'
import { BadRequestPayload } from 'types/Errors'
import i18n from 'i18n'
import { UseDeclarationFormReturn } from '../../form'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import useConsignmentItemApi from './api'
import { parseCreateOrUpdateConsignmentItemResponse, toCreateOrUpdateConsignmentItemRequest } from './mapper'
import { HouseConsignmentType } from '../../form/schemas/houseConsignmentSchema'
import { ConsignmentItemResponse, CreateOrUpdateConsignmentItemRequest } from '../../../common/models'
import { MutateRequest } from '../request-type'
import { sortBySequenceNumber } from '../../services/useFieldArrayActionHelper'
import {
  adjustToHumanReadableField,
  excludeDeleted,
  hasText,
  nonNullConsignee,
  replaceListSquareBracketsWithDotDelimiter,
} from '../../../common/utils/common-util'
import useConsignmentDetail from '../useConsignmentDetail'
import { ConsignmentItem } from '../../form/schemas/consignmentItemSchema'
import { NctsError, TransitOperationContext } from '../useTransitOperationContext'

const consignmentItemScope = /houseConsignment\.\d+\.consignmentItem\.\d+/g
export const isConsignmentItemScope = (scope: string): scope is `houseConsignment.${number}.consignmentItem.${number}` => (
  Array.from(scope.matchAll(consignmentItemScope))?.length ?? 0) > 0

const ERROR_FIELD_REASONS = ['CommodityCode', 'ValidInputText']

function adjustFieldToScopePath(fieldPath: string) {
  let path = fieldPath
  if (path.endsWith('null')) {
    path = path.replace('null', 'commodityHarmonizedSystemSubHeadingCode')
  } else if (path.endsWith('descriptionOfGoods')) {
    path = path.replace('descriptionOfGoods', 'commodityDescriptionOfGoods')
  }
  return path
}

function prependConsignmentItemScope(fieldPath: string) {
  return `houseConsignment.0.consignmentItem${fieldPath}`
}

function useConsignmentItem(form: UseDeclarationFormReturn) {
  const {
    reset,
    trigger,
    setValue,
    getValues,
    formState: {
      isValid,
      isSubmitting,
    },
  } = form
  const { t } = useTranslation()
  const { nctsErrors, setNctsErrors } = useContext(TransitOperationContext)
  const { createConsignmentItemConsignee } = useConsignmentDetail(form)

  const {
    fetchConsignmentItems,
    postConsignmentItem,
    deleteConsignmentItem,
    putConsignmentItem,
  } = useConsignmentItemApi(getValues('houseConsignment').map((house) => house.id), isSubmitting)

  const populateFormConsignmentItems = () => {
    if (fetchConsignmentItems.some((itemQuery) => itemQuery.isLoading) || isSubmitting) {
      return
    }

    const responses = fetchConsignmentItems
      .flatMap((itemQuery) => itemQuery.data)
      .map((response, index) => parseCreateOrUpdateConsignmentItemResponse(response, index))
      .sort(sortBySequenceNumber)

    if (nctsErrors.length > 0 && responses.length === 0) {
      return
    }
    const formClone: DeclarationForm = structuredClone(getValues())
    reset({
      ...formClone,
      houseConsignment: formClone.houseConsignment.map((formHouseConsignment) => ({
        ...formHouseConsignment, // This is needed so that transitional fields are not written as invalid undefined values
        consignmentItem: responses.map((consignmentItemResponse) => {
          const formConsignmentItem = formHouseConsignment.consignmentItem
            .find((item) => (item.id === consignmentItemResponse.id) || (item.sequenceNumber === consignmentItemResponse.sequenceNumber))
          return ({
            ...formConsignmentItem, // This is needed so that transitional fields are not written as invalid undefined values
            ...consignmentItemResponse,
          })
        }),
      })),
    })
  }
  // TODO Flatten request type - T1-1644 https://tanstack.com/query/latest/docs/react/guides/request-waterfalls
  const data = useMemo(() => fetchConsignmentItems.map((value) => value.data), [...fetchConsignmentItems.map((value) => value.data)])

  useEffect(() => {
    populateFormConsignmentItems()
  }, [data])

  function refreshSavedIds(houseConsignments: HouseConsignmentType[], response: ConsignmentItemResponse[]) {
    houseConsignments.forEach((houseConsignment, houseConsignmentIndex) => {
      houseConsignment.consignmentItem.forEach((consignmentItem, consignmentItemIndex) => {
        const savedItem = response.find((responseItem) => responseItem.goodsItemNumber === consignmentItem.sequenceNumber)
        if (savedItem) setValue(`houseConsignment.${houseConsignmentIndex}.consignmentItem.${consignmentItemIndex}.id`, savedItem.id)
      })
    })
  }

  const createOrUpdateConsignmentItems = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return

    const houseConsignments = getValues('houseConsignment')
    const itemConsigneePromises: Array<Promise<void>> = []

    houseConsignments.forEach((houseConsignment, houseIndex) => {
      houseConsignment.consignmentItem.forEach((consignmentItem, itemIndex) => {
        if (consignmentItem.consignee !== null && nonNullConsignee(consignmentItem.consignee)) {
          itemConsigneePromises.push(createConsignmentItemConsignee(isDraft, houseIndex, itemIndex))
        }
      })
    })
    await Promise.allSettled(itemConsigneePromises)

    const createRequestsForHouseConsignment: Array<MutateRequest<CreateOrUpdateConsignmentItemRequest[]>> = []
    const updateRequestsForHouseConsignment: Array<MutateRequest<CreateOrUpdateConsignmentItemRequest[]>> = []
    houseConsignments
      .filter(excludeDeleted)
      .forEach((houseConsignment) => {
        const houseConsignmentId = houseConsignment.id

        if (houseConsignmentId === null) throw Error('Missing required house consignment id for consignment item')
        const newConsignmentItems: CreateOrUpdateConsignmentItemRequest[] = []
        const updatedConsignmentItems: CreateOrUpdateConsignmentItemRequest[] = []

        houseConsignment.consignmentItem.sort(sortBySequenceNumber)
        houseConsignment
          .consignmentItem
          .filter(excludeDeleted)
          .map((item: ConsignmentItem, consignmentItemIndex: number) => (
            { ...item, sequenceNumber: consignmentItemIndex, declarationGoodsItemNumber: consignmentItemIndex }))
          .map((consignmentItem) => toCreateOrUpdateConsignmentItemRequest(
            consignmentItem,
            houseConsignmentId,
          ))
          .forEach((request) => {
            if (request.id == null) {
              newConsignmentItems.push(request)
            } else {
              updatedConsignmentItems.push(request)
            }
          })

        if (newConsignmentItems.length > 0) {
          createRequestsForHouseConsignment.push({
            id: houseConsignmentId,
            data: newConsignmentItems,
          })
        }
        if (updatedConsignmentItems.length > 0) {
          updateRequestsForHouseConsignment.push({
            id: houseConsignmentId,
            data: updatedConsignmentItems,
          })
        }
      })

    const asyncRequests: Array<Promise<ConsignmentItemResponse[]>> = []
    createRequestsForHouseConsignment.forEach((request) => asyncRequests.push(postConsignmentItem(isDraft).mutateAsync(request)))
    updateRequestsForHouseConsignment.forEach((request) => asyncRequests.push(putConsignmentItem(isDraft).mutateAsync(request)))

    const responses = await Promise.allSettled(asyncRequests)
    responses.forEach((response) => {
      if (response.status === 'fulfilled') {
        refreshSavedIds(houseConsignments, response.value)
      }
      if (response.status === 'rejected') {
        const rejectedData = response.reason?.response?.data
        if (rejectedData?.message === 'CONSTRAINT_VIOLATION') {
          const apiNctsErrors: NctsError[] = [];
          (rejectedData?.errors ?? []).forEach((errorItem: BadRequestPayload) => {
            const translatedString = mapReasonToTranslationString(errorItem)

            if (hasText(translatedString)) {
              let errorField = errorItem.field
              if (ERROR_FIELD_REASONS.includes(errorItem.reason)) {
                errorField = adjustFieldToScopePath(errorField)

                const path = replaceListSquareBracketsWithDotDelimiter(errorField)
                const fullPath = prependConsignmentItemScope(path)
                if (isConsignmentItemScope(fullPath)) {
                  apiNctsErrors.push(
                    {
                      field: fullPath,
                      description: errorItem.message ?? 'Invalid field',
                    },
                  )
                }
              }
              toast.error(`${t(`translations${i18n.language.toUpperCase()}:${
                adjustToHumanReadableField(errorField)
              }`)} ${translatedString}`)
            } else {
              let errorField = errorItem.field
              if (ERROR_FIELD_REASONS.includes(errorItem.reason)) {
                errorField = adjustFieldToScopePath(errorField)

                toast.error(`Field ${adjustToHumanReadableField(errorField)} ${errorItem.message}`)
              } else {
                toast.error(`Field ${adjustToHumanReadableField(errorField)} ${errorItem.message}`)
              }
            }
          })
          setNctsErrors(apiNctsErrors)
        } else {
          toast.error(t('common.savingFailed'))
        }
      }
    })
    const anyError = responses.find((promise) => promise.status === 'rejected')
    if (anyError?.status === 'rejected') {
      throw Error(anyError?.reason)
    }
  }

  const archiveConsignmentItems = async (isDraft: boolean) => {
    await trigger()
    if (!isDraft && !isValid) return

    const houseConsignments = getValues('houseConsignment')

    type ConsignmentItemId = number
    const archivedItems: Array<MutateRequest<ConsignmentItemId>> = []

    houseConsignments
      .forEach((houseConsignment) => {
        const houseConsignmentId = houseConsignment.id
        if (houseConsignmentId === null) throw Error('Missing required house consignment id for consignment item')
        const deletedItem = houseConsignment
          .consignmentItem
          .filter((item) => item.deleted === true && item.id !== null)
          .map((consignmentItem) => ({
            id: houseConsignmentId!,
            data: consignmentItem.id!,
          }))

        archivedItems.push(...deletedItem)
      })

    await Promise.allSettled(archivedItems.map((request) => deleteConsignmentItem.mutateAsync(request)))
  }

  return {
    createOrUpdateConsignmentItems,
    archiveConsignmentItems,
  }
}

export default useConsignmentItem
