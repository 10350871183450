import React, { useEffect, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useExpanded, useTable } from 'react-table'
import { useTranslation } from 'react-i18next'
import { compareAsc, parseISO } from 'date-fns'
import { EventLogSubRow } from 'types/EventLog'
import DocumentService from 'routes/Declaration/services/document.service'
import useTransitOperationLog from '../../hooks/useTransitOperationLog'
import { TransitOperationLogResponse } from '../../../common/models'
import { dateFormatWithTimeOptions } from '../../../../../config/dateFormatterConfig'
import { DeclarationForm } from '../../form/schemas/declarationFormSchema'
import messages from '../../../../../messages/error-messages'

interface EventLogRow {
  id: number | undefined
  date: string
  subRows: Array<EventLogSubRow>
  type: string
  message: string | undefined
  status: 'REFUSED' | '' | 'ACCEPTED'
  created?: Date
}

const SUBMIT_MESSAGE_TYPES = ['IE015', 'FI015A']

function EventLog() {
  const { getValues } = useFormContext<DeclarationForm>()
  const id = useMemo(() => getValues('id'), [getValues('id')])

  const { fetchTransitOperationLogs, fetchSubmitMessage } = useTransitOperationLog(id!)

  const { t } = useTranslation()

  const [logs, setLogs] = useState<EventLogRow[]>([])

  function isSubmitMessage(messageType: string | undefined): boolean {
    return SUBMIT_MESSAGE_TYPES.some((submitMessageType) => messageType?.includes(submitMessageType))
  }

  function downloadSubmitMessage(logId: number) {
    fetchSubmitMessage.mutateAsync({
      transitOperationLogId: logId,
    }).then(async (response) => {
      const [, filename] = response.headers['content-disposition'].split('filename=')

      DocumentService.saveBlob(null, response.data, 'blob', filename)
    })
  }

  function mapToRows(eventLogs: Array<TransitOperationLogResponse>): EventLogRow[] {
    return eventLogs.map((log: TransitOperationLogResponse) => {
      const {
        functionalErrors, messageContext, created,
      } = log
      let status: 'ACCEPTED' | 'REFUSED' | ''
      if (messageContext === 'IE028'
        || messageContext === 'IE928'
        || (messageContext === 'IE014')) {
        status = 'ACCEPTED'
      } else if (messageContext === 'IE906' || messageContext === 'IE917' || messageContext === 'IE056') {
        status = 'REFUSED'
      } else {
        status = functionalErrors!.length > 0 ? 'REFUSED' : ''
      }
      let date = ''
      if (created !== undefined) {
        date = new Intl.DateTimeFormat('et', dateFormatWithTimeOptions).format(new Date(created))
      }

      const subRows: Array<EventLogSubRow> = []
      subRows.push(...functionalErrors!
        .map((functionalError) => ({
          subType: functionalError.type,
          reason: functionalError.reason,
          pointer: functionalError.pointer,
          value: functionalError.originalAttributeValue,
          description: functionalError.description ?? '',
        } as EventLogSubRow)))

      return (
        {
          id: log.id,
          status,
          date,
          created,
          type: 'form',
          message: messageContext,
          subRows,
        })
    }).sort((a, b) => {
      if (a.created === undefined || b.created === undefined) {
        return 0
      }
      return compareAsc(parseISO(`${a.created}`), parseISO(`${b.created}`))
    })
  }

  useEffect(() => {
    if (fetchTransitOperationLogs.data) {
      setLogs(mapToRows(fetchTransitOperationLogs.data))
    }
  }, [fetchTransitOperationLogs.data])

  const columns = React.useMemo(() => [
    {
      Header: 'Date',
      accessor: 'date',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Message',
      accessor: 'message',
    },
  ], [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    /* eslint-disable  @typescript-eslint/no-explicit-any */
  } = useTable<any>({ columns, data: logs }, useExpanded)

  const [preExpandFirstRow, setPreExpandFirstRow] = useState(false)

  return (
    <div className="container-fluid py-3">
      <table
        className="table table-sm table-borderless data-table me-2"
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map((headerGroup) => (
          /* eslint-disable-next-line react/jsx-props-no-spreading */
            <tr {...headerGroup.getHeaderGroupProps()} key={`${headerGroup.id} ${headerGroup.columns?.length}`}>
              <th className="log-status" aria-label="status" />
              {headerGroup.headers.map((column) => (
                <th
                /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...column.getHeaderProps()}
                  key={column.id}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row)
            if (preExpandFirstRow && rows[0].canExpand) {
              if (rows[0]) {
                rows[0].toggleRowExpanded(true)
              }
              setPreExpandFirstRow(false)
            }

            return (
              <React.Fragment key={row.id + row.cells.length}>
                {(
                  <tr className={` ${row.id}`} {...row.getRowProps()}>
                    <td className="log-status">
                      {
                      // eslint-disable-next-line no-nested-ternary
                        (row.original.status === 'ACCEPTED')
                          ? <i className="fal fa-check-circle text-success fa-md me-1" />
                          : null
                      }
                      {(row.original.status === 'REFUSED')
                        ? <i className="fal fa-exclamation-triangle text-danger fa-md me-1" />
                        : ''}
                    </td>
                    {row.cells.map((cell) => (
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                      <td {...cell.getCellProps()}>
                        {cell.column.Header === 'Message'
                          ? (
                            <>
                              <button
                                className="btn btn-link border-0 p-0 me-2 text-nowrap"
                                type="button"
                                /* eslint-disable-next-line react/jsx-props-no-spreading */
                                {...row.getToggleRowExpandedProps()}
                              >
                                {cell.render('Cell')}
                              </button>
                              {isSubmitMessage(row.original.message) && (
                                <button
                                  type="button"
                                  onClick={() => downloadSubmitMessage(row.original.id)}
                                >
                                  {t('buttons.downloadXml')}
                                </button>
                              )}
                            </>
                          )
                          : cell.render('Cell')}
                      </td>
                    ))}
                  </tr>
                )}
                {row.isExpanded && (
                  row.subRows.map((subRow, j) => (
                    <tr className="log-row" key={subRow.id}>
                      <td
                        colSpan={row.cells.length + 1}
                        className={`log-row__messages ${
                          // eslint-disable-next-line no-nested-ternary
                          (row.subRows.length <= 1) ? 'first last'
                          // eslint-disable-next-line no-nested-ternary
                            : (j === 0) ? 'first'
                              : (j === row.subRows.length - 1) ? 'last'
                                : ''}`}
                      >
                        <div className="log-row__messages-container">
                          <div className="log-row__messages-column w-auto">
                            <h5>Type</h5>
                            <span>
                              {subRow.original.subType}
                            </span>
                          </div>
                          <div className="log-row__messages-column w-auto">
                            <h5>Reason</h5>
                            {(subRow.original.subType === 'DmeDocumentInfoMessage' || subRow.original.subType === 'FILE') ? (
                              <button
                                type="button"
                                className="download-link"
                                style={{ cursor: 'pointer' }}
                                onClick={() => DocumentService.saveBlob(null, subRow.original.reason, 'url', subRow.original.value)}
                              >
                                {t('buttons.downloadPdf')}
                              </button>
                            ) : <span>{subRow.original.reason}</span>}
                          </div>
                          <div className="log-row__messages-column">
                            <h5>Pointer</h5>
                            <span>
                              {subRow.original.pointer}
                            </span>
                          </div>
                          <div className="log-row__messages-column">
                            <h5>Value</h5>
                            <span>
                              {subRow.original.value}
                            </span>
                          </div>
                          <div className="log-row__messages-column">
                            <h5>Description</h5>
                            <span>
                              {subRow.original.description
                                ? subRow.original.description
                                : messages.errors.find((itm) => itm.code === subRow.original.reason)?.en}
                            </span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  )))}
              </React.Fragment>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export default EventLog
